.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px !important;
  margin-left: -75px !important;
}

.ui.modal.custom>.close {
  top: 0.5rem;
  right: 0.5rem;
  color: #8798ad;
}

.error-centered {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -50px !important;
  margin-left: -155px !important;
}

.progress-style {
  display: grid;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.container {
  max-width: 1024px;
  margin-left: auto;
  margin-right: auto
}